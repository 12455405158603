import styled from '@emotion/styled';
import Image from 'next/image';
import { CurrentUserProfileFieldsFragment } from '../../../../generated/graphql';
import { MediaContainer } from '../../../media-container/media-container';
import ProfileBlobSvg from '../../svgs/profile-blob.svg';

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const ProfileBlob = styled.div`
  position: absolute;
  top: -10%;
  left: -20%;
  width: 110%;
  height: 110%;
  z-index: 1;
  pointer-events: none;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const StyledMediaContainer = styled(MediaContainer)`
  z-index: 2;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 60% 70% 70% 60%;
`;

export type AvatarProps = {
  profile?: CurrentUserProfileFieldsFragment;
  bg?: boolean;
};

export const Avatar = ({ profile, bg }: AvatarProps): React.ReactElement => {
  const image = profile?.avatar?.url || '/public/person.png';

  return (
    <Container>
      <StyledMediaContainer>
        {profile && <Image src={image} alt={`${profile?.nickname}`} fill />}
      </StyledMediaContainer>
      {bg && (
        <ProfileBlob>
          <ProfileBlobSvg />
        </ProfileBlob>
      )}
    </Container>
  );
};
