import { useAuth0 } from '@/auth';
import { YellowArrow } from '@/components/icons/icons';
import { theme } from '@/app/common/providers/theme-provider/theme';
import styled from '@emotion/styled';
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { useState } from 'react';
import { AuthMenu } from '../../auth-menu/auth-menu';
import { ExperienceSearch } from '../../experience-search/experience-search';
import { Link } from '../../link/link';
import BurgerMenu from './svgs/burger-menu.svg';
import CloseIcon from './svgs/close-button.svg';
import LogoSvg from './svgs/logo.svg';
import TitleSvg from './svgs/title.svg';

const AppBarContainer = styled(AppBar)`
  background-color: #251628;
  min-height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: ${theme.zIndex.drawer + 99};
  & a {
    :hover {
      text-decoration: none;
    }
  }
`;
const AppToolbar = styled(Toolbar)`
  width: 100%;
  padding-right: 4%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const LogoDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
  max-width: max-content;
  :hover {
    cursor: pointer;
  }
`;
const MenuItem = styled.div`
  max-width: 100px;
  margin: 0 21px;
`;
const MenuItemText = styled(Typography)`
  font-family: var(--font-work-sans);
  font-weight: 600;
  font-size: 15px;
  line-height: 136.8%;
  color: #fdf3de;
  text-align: center;
  :hover {
    color: ${({ theme }) => theme.palette.common.yellow};
    cursor: pointer;
  }
`;
const LinksAndButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;
const MenuContainer = styled(Box)`
  width: 100%;
  background: ${({ theme }) => theme.palette.common.cream};
  height: max-content;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  padding: 60px 20px;
  & a {
    :hover {
      text-decoration: none;
    }
  }
`;
const MenuItemMobile = styled(Typography)`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 136.8%;
  color: ${({ theme }) => theme.palette.common.midnight};
`;
const ListItemMobile = styled.div`
  width: 100%;
  height: 75px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid rgba(76, 59, 81, 0.3);
  :hover {
    cursor: pointer;
  }
`;
const MenuItems = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top: 3px solid #c699bb;
`;
const StyledRightMenu = styled.div`
  background-color: transparent;
`;
const MenuTitle = styled(Typography)`
  font-family: var(--font-cooper-light);
  font-style: normal;
  font-weight: 300;
  font-size: 50px;
  line-height: 109.3%;
  color: ${({ theme }) => theme.palette.common.midnight};
  margin-bottom: 60px;
`;
const CloseButton = styled(IconButton)`
  position: absolute;
  top: 20px;
  right: 20px;
  height: 26px;
`;

const ExperienceSearchContainer = styled.div`
  display: none;
  margin-left: 80px;
  width: 120px;

  ${theme.breakpoints.up('sm')} {
    display: block;
  }
`;

const TopNavbar = () => {
  const theme = useTheme();
  const { isAuthenticated, logout, loginWithRedirect } = useAuth0();
  const [state, setState] = useState({
    right: false,
  });

  const toggleSideMenu = (slider: string, open: boolean) => () => {
    setState({ ...state, [slider]: open });
  };
  const isMobile = useMediaQuery('(max-width:1080px)');
  const AnonymousMenuItems = () => {
    return (
      <>
        <ListItemMobile onClick={() => loginWithRedirect()}>
          <MenuItemMobile>Login</MenuItemMobile>
          <IconButton>
            <YellowArrow />
          </IconButton>
        </ListItemMobile>
        <ListItemMobile
          onClick={() =>
            loginWithRedirect({
              screen_hint: 'signup',
            })
          }
        >
          <MenuItemMobile>SignUp</MenuItemMobile>
          <IconButton>
            <YellowArrow />
          </IconButton>
        </ListItemMobile>
      </>
    );
  };
  const AuthenticatedMenuItems = () => {
    return (
      <>
        <ListItemMobile onClick={() => logout()}>
          <MenuItemMobile>Logout</MenuItemMobile>
          <IconButton>
            <YellowArrow />
          </IconButton>
        </ListItemMobile>
      </>
    );
  };
  return (
    <AppBarContainer position="fixed">
      <AppToolbar>
        <Box display="flex" flexDirection="row">
          <Link href="/">
            <LogoDiv>
              <LogoSvg />
              <TitleSvg />
            </LogoDiv>
          </Link>
          <ExperienceSearchContainer>
            <ExperienceSearch />
          </ExperienceSearchContainer>
        </Box>
        {isMobile ? (
          <>
            <IconButton onClick={toggleSideMenu('right', true)}>
              <BurgerMenu />
            </IconButton>
            <Drawer
              PaperProps={{ component: StyledRightMenu }}
              open={state.right}
              anchor="bottom"
              onClose={toggleSideMenu('right', false)}
            >
              <MenuContainer component="div">
                <CloseButton onClick={toggleSideMenu('right', false)}>
                  <CloseIcon />
                </CloseButton>
                <MenuTitle>Menu</MenuTitle>
                <MenuItems>
                  <Link href="/about">
                    <ListItemMobile onClick={toggleSideMenu('right', false)}>
                      <MenuItemMobile>About</MenuItemMobile>
                      <IconButton>
                        <YellowArrow />
                      </IconButton>
                    </ListItemMobile>
                  </Link>
                  <Link href="/contact-us">
                    <ListItemMobile onClick={toggleSideMenu('right', false)}>
                      <MenuItemMobile>Contact</MenuItemMobile>
                      <IconButton>
                        <YellowArrow />
                      </IconButton>
                    </ListItemMobile>
                  </Link>

                  <Link href="/explore">
                    <ListItemMobile onClick={toggleSideMenu('right', false)}>
                      <MenuItemMobile>Discover Experiences</MenuItemMobile>
                      <IconButton>
                        <YellowArrow />
                      </IconButton>
                    </ListItemMobile>
                  </Link>

                  <Link href="/discover/hosting">
                    <ListItemMobile onClick={toggleSideMenu('right', false)}>
                      <MenuItemMobile>Create an Experience</MenuItemMobile>
                      <IconButton>
                        <YellowArrow />
                      </IconButton>
                    </ListItemMobile>
                  </Link>

                  {isAuthenticated ? <AuthenticatedMenuItems /> : <AnonymousMenuItems />}
                </MenuItems>
              </MenuContainer>
            </Drawer>
          </>
        ) : (
          <>
            <LinksAndButtons>
              <Link href="/about">
                <MenuItem>
                  <MenuItemText>About</MenuItemText>
                </MenuItem>
              </Link>
              <Link href="/contact-us">
                <MenuItem>
                  <MenuItemText>Contact</MenuItemText>
                </MenuItem>
              </Link>
              <Link href="/explore">
                <MenuItem>
                  <MenuItemText>Discover Experiences</MenuItemText>
                </MenuItem>
              </Link>
              <Link href="/discover/hosting">
                <MenuItem>
                  <MenuItemText>Create an Experience</MenuItemText>
                </MenuItem>
              </Link>
              <AuthMenu />
            </LinksAndButtons>
          </>
        )}
      </AppToolbar>
    </AppBarContainer>
  );
};

export default TopNavbar;
