import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import {
  useMarkReadMutation,
  CountUnreadThreadsDocument,
  MessageThread,
} from '../../../../generated/graphql';
import { MediaContainer } from '@/components/media-container/media-container';
import { useCurrentUser } from '@/hooks/use-current-user/use-current-user';
import { useRouter } from 'next/navigation';
import { dateOrTime } from '@/utils/dateOrTime';
import { DeepPartial } from 'redux';
import NotificationDot from '../../svgs/noti-dot.svg';
import Image from 'next/image';
import { buildImageSource } from '@/utils/buildImageSource';

const Card = styled.div`
  width: 100%;
  height: auto;
  max-height: 84px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-grow: 1;
  align-items: stretch;
  padding: 12px 0;
  overflow: hidden;
`;
const UserAvatar = styled(MediaContainer)`
  z-index: 2;
  position: relative;
  width: 56px;
  height: 56px;
  overflow: hidden;
  border-radius: 60% 70% 70% 60%;
`;
const Username = styled(Typography)`
  font-family: var(--font-work-sans);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  color: #231727;
`;
const ColumnRows = styled.div`
  display: flex;
  height: 100%;
  flex-grow: 3;
  padding-left: 12px;
`;
const UserDetails = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const MessageOrDate = styled(Typography)`
  font-family: var(--font-work-sans);
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  align-items: center;
  color: #231727;
  opacity: 0.6;
`;
const NameAndDate = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;
const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 6px;
  width: 30%;
  text-align: right;
`;
interface ChatMenuItemProps {
  thread: DeepPartial<MessageThread>;
  handleClick?: () => void;
}
const ChatMenuItem = ({ thread, handleClick }: ChatMenuItemProps) => {
  const userId = useCurrentUser()?.data?.id;
  const router = useRouter();
  const otherUserProfile = thread?.threadParticipants?.edges?.filter(f => f.node.userId !== userId);
  const lastMessage = thread?.messages?.edges[0]?.node;
  const profile = otherUserProfile?.[0]?.node?.user?.profile;
  const image = buildImageSource(profile?.avatar?.sources?.[0]) || '/public/person.png';
  const currentParticipant = thread?.threadParticipants?.edges?.filter(
    f => f.node.userId === userId,
  )[0]?.node;
  const [markReadMutation] = useMarkReadMutation();
  const handleThreadClick = () => {
    handleClick?.();
    if (currentParticipant?.unread === true) {
      markReadMutation({
        variables: {
          id: currentParticipant?.id,
        },
        refetchQueries: [CountUnreadThreadsDocument],
      });
    }
  };
  return (
    <Card
      onClick={() => {
        handleThreadClick();
        router.push(`/chat/${thread?.id}`);
      }}
    >
      <UserAvatar>
        {otherUserProfile && (
          <Image src={image} alt={`${profile?.nickname}`} width={200} height={200} />
        )}
      </UserAvatar>
      <ColumnRows>
        <UserDetails>
          <NameAndDate>
            <Username
              style={
                currentParticipant?.unread === true
                  ? { fontWeight: 'bold' }
                  : { fontWeight: 'normal' }
              }
            >
              {profile?.nickname}
            </Username>
            <DateContainer>
              {currentParticipant?.unread && <NotificationDot />}
              <MessageOrDate>
                {lastMessage?.updatedAt && dateOrTime(lastMessage?.updatedAt)}
              </MessageOrDate>
            </DateContainer>
          </NameAndDate>
          {(lastMessage?.messageFiles?.edges?.length || 0) > 0 &&
            lastMessage?.user?.id !== userId && (
              <MessageOrDate>`{lastMessage?.user?.nickname} shared picture(s)`</MessageOrDate>
            )}
          {(lastMessage?.messageFiles?.edges?.length || 0) > 0 &&
            lastMessage?.user?.id === userId && (
              <MessageOrDate>you sent a picture message</MessageOrDate>
            )}
          {lastMessage?.messageFiles?.edges.length === 0 &&
            (lastMessage?.content?.length || 0) <= 30 && (
              <MessageOrDate>{lastMessage?.content}</MessageOrDate>
            )}
          {lastMessage?.messageFiles?.edges.length === 0 &&
            (lastMessage?.content?.length || 0) > 30 && (
              <MessageOrDate>{`${lastMessage?.content?.substring(0, 30)}...`}</MessageOrDate>
            )}
        </UserDetails>
      </ColumnRows>
    </Card>
  );
};

export default ChatMenuItem;
